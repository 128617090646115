import { doc, getDoc } from 'firebase/firestore';
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../context/AuthContext';
import { usersFirestore } from '../../services/firebase/firebase';
import Section from '../Section';
import ActiveSessions from './active-sessions';
import Loading from '../../components/Loading';
import Connections from './connections';

export default function DataAndPrivacy() {
    const { user } = useContext(AuthContext);

    const [ loading, setLoading ] = useState(false);

    if (loading) return <Loading />

    return (
        <div className='section-container'>
            <Section
                title={"Apps y Servicios"}
                desc={"Administra las apps que tienes vinculadas con Yüga."}
                moreInfo={'#'}
            >
                <Connections 
                    setPrincipalLoading={setLoading}
                />
            </Section>
            <Section
                title={"Sesiones activas"}
                desc={"Administra las sesiones en las que tu cuenta esta conectada."}
                moreInfo={'#'}
            >
                <ActiveSessions 
                    setPrincipalLoading={setLoading}
                 />
            </Section>
        </div>
    )
}
