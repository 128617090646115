import { Image, Rotate90DegreesCcw, Rotate90DegreesCw } from '@mui/icons-material';
import { Button, ButtonGroup, Slider } from '@mui/material';
import React, { useContext, useRef, useState } from 'react'
import AvatarEditor from 'react-avatar-editor'
import Compressor from 'compressorjs';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { auth, usersFirestore, usersStorage } from '../../../../services/firebase/firebase';
import { AuthContext } from '../../../../context/AuthContext';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { updateProfile } from 'firebase/auth';
import { enqueueSnackbar } from 'notistack';
import SlideTransition from '../../../../components/transition/SlideTransition';

export default function PfpEditor({ originalImage, image, back, close, setLoading, setPrincipalLoading }) {
  const cropRef = useRef(null);
  const { user } = useContext(AuthContext);
  const [ scale, setScale ] = useState(10);
  const [ rotate, setRotate ] = useState(0);

  const finished = (success) => {
    setLoading(false);
    close();
    if (success) {
      sessionStorage.removeItem('personal-info');
      enqueueSnackbar('¡Todo ha salido bien! Los cambios pueden tardar unos minutos en propagarse.', { variant: 'success', autoHideDuration: 3000, TransitionComponent: SlideTransition })  
    } else {
      enqueueSnackbar('Algo salió mal.', { variant: 'error', autoHideDuration: 3000, TransitionComponent: SlideTransition })  
    }
    setTimeout(() => {
      close();
      setPrincipalLoading(true);
    }, 100)
  }

  const handleSave = (image) => {
    const isDomainAllowed = (url) => {
      const allowedDomain = "firebasestorage.googleapis.com";
      try {
        const urlObj = new URL(url);
        return urlObj.hostname === allowedDomain;
      } catch (error) {
        return false;
      }
    }

    async function getPersonalInfo(url) {
      const storage = sessionStorage.getItem('personal-info');

      if (storage) {
        const arr = JSON.parse(sessionStorage.getItem('personal-info'));
        arr.basic.photoURL = url;

        
        const userRef = doc(usersFirestore, `${user.uid}/personal-info`);
        updateDoc(userRef, {
          data: [arr]
        })
      } else {
          const userRef = doc(usersFirestore, `${user.uid}/personal-info`);
          const userSnap = await getDoc(userRef);
  
          if (userSnap.exists()) {
              const data = userSnap.data().data[0];
              data.basic.photoURL = url;
  
              updateDoc(userRef, {
                data: [data]
              })
          } else {
              console.error('No existe el documento');
          }
      }
    }

    const userRef = ref(usersStorage, `${user.uid}/public/pfp.png`);
    uploadBytes(userRef, image)
      .then((snapshot) => {
        getDownloadURL(snapshot.ref)
          .then((url) => {
            if (isDomainAllowed(originalImage)) {
              finished(true);
            } else {
              getPersonalInfo(url)
              .then(() => {
                const userRef = doc(usersFirestore, `${user.uid}/public`);
                setDoc(userRef, {
                  data: [{
                    email: user.email,
                    name: user.displayName,
                    photoURL: url
                  }]
                })
                .then(() => {
                  updateProfile(auth.currentUser, {
                    photoURL: url
                  }).then(() => {
                    finished(true);
                  })
                })
              })
            }
          })
      })

  }

  const handleCompress = async () => {
    setLoading(true);

    if (cropRef) {
      const dataUrl = cropRef.current.getImageScaledToCanvas().toDataURL();
      const result = await fetch(dataUrl);
      const blob = await result.blob();
      new Compressor(blob, {
        quality: .7,
        width: 250,
        height: 250,
        resize: 'cover',
        convertTypes: 'image/png',
        convertSize: 50000,
        success: (compressedResult) => {
          handleSave(compressedResult);
        }
      })
    }
  }

  return (
    <>
      <div className='image-editor'>
        <AvatarEditor 
          ref={cropRef}
          image={image}
          style={{ width: "100%", height: "100%" }}
          scale={scale / 10}
          rotate={rotate}
          width={600}
          color={[255, 255, 255, .6]}
          height={600}
          border={[100, 0]}
          borderRadius={1000}
          className='canvas'
        />
        <div className="cutter"></div>
      </div>
      <div className='avatar-controls'>
        <div className="slider">
          <Image />
          <Slider 
            min={10}
            max={50}
            size='small'
            defaultValue={scale}
            onChange={(e) => setScale(e.target.value)}
            valueLabelDisplay='off'
          />
          <Image style={{ fontSize: '2rem'}} />
        </div>
        <ButtonGroup className='buttons-group'>
          <Button onClick={() => setRotate(rotate + -90)}><Rotate90DegreesCcw /></Button>
          <Button onClick={() => setRotate(rotate + 90)}><Rotate90DegreesCw /></Button>
        </ButtonGroup>
      </div>
      <div className='flex flow-row justify-between' style={{ marginTop: '2rem', gap: '1rem'}}>
        <Button className='btn light' style={{ minWidth: '40%'}} onClick={back}>
          Volver
        </Button>
        <Button className='btn high' onClick={handleCompress}  style={{ minWidth: '40%'}}>
          Subir
        </Button>
      </div>
    </>
  )
}
