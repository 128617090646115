import React, { useContext, useEffect, useState } from 'react'
import SectionButton from '../../../components/buttons/SectionButton'
import { CloudOff, Delete, Settings, Share } from '@mui/icons-material'
import Modal from '../../../components/modal/Modal';
import { arrayRemove, arrayUnion, doc, getDoc, updateDoc } from 'firebase/firestore';
import { auth, sessionsFirestore, usersFirestore } from '../../../services/firebase/firebase';
import decrypt from '../../../functions/decrypt';
import { AuthContext } from '../../../context/AuthContext';
import { deleteSession } from '../../../functions';
import { enqueueSnackbar } from 'notistack';
import SlideTransition from '../../../components/transition/SlideTransition';
import CardButton from '../../../components/buttons/CardButton';
import Loading from '../../../components/Loading';
import { Button, Skeleton, TextField } from '@mui/material';

export default function ActiveSessions({ setPrincipalLoading }) {
  const { user } = useContext(AuthContext);
  const [ sessions, setSessions ] = useState([]);

  const [ open, setOpen ] = useState(false);

  const [ shareSync, setShareSync ] = useState(false);

  const [ decryptUid, setDecryptUid ] = useState('');
  const [ modalData, setModalData ] = useState({
    id: '',
    owner: '',
    ownerId: '',
    expired: false,
    sessionOwnerId: '',
    timestamp: NaN
  })

  const [ loading, setLoading ] = useState(true);
  const [ inheritLoading, setInheritLoading ] = useState(true);

  const handleOpen = async (session) => {
    setOpen(true);
    setInheritLoading(true);
    try {
      setModalData({
        id: session.sessionId,
        owner: session.name,
        ownerId: session.ownerId,
        expired: session.expired,
        sessionOwnerId: session.sessionOwnerId,
        timestamp: session.timestamp
      })

      const sessionOwner = await decrypt(session.sessionOwnerId);
      setDecryptUid(sessionOwner);

      setInheritLoading(false);
    } catch (error) {
      enqueueSnackbar('Ocurrió un error :(', { variant: 'error', autoHideDuration: 3000, TransitionComponent: SlideTransition });
      handleClose();
    }    
  }

  const handleClose = () => {
    setLoading(false);
    setOpen(false);
    setTimeout(() => {
      setDecryptUid('')
    }, 100)
  }

  const handleFullClose = () => {
    setLoading(false);
    setOpen(false);
    setTimeout(() => {
      setDecryptUid('');
      setModalData({
        id: '',
        owner: '',
        ownerId: '',
        expired: false,
        sessionOwnerId: '',
        timestamp: NaN
      })
    }, 100)
  }

  const handleShareSyncOpen = () => {
    setShareSync(true);
  }

  const handleShareSyncClose = () => {
    setShareSync(false);
    setLoading(false);
  }

  const handleShare = () => {
    setLoading(true);

    setTimeout(() => {
      handleShareSyncOpen();
      setTimeout(() => {
        handleClose();
      }, 50)
    }, 500)
  }

  const handleBack = () => {
    handleOpen({
      expired: modalData.expired,
      name: modalData.owner,
      ownerId: modalData.ownerId,
      sessionId: modalData.id,
      sessionOwnerId: modalData.sessionOwnerId,
      timestamp: modalData.timestamp
    });
    setTimeout(() => {
        handleShareSyncClose();
    }, 50)
}

  const handleDisconnectSession = async () => {
    setLoading(true);
    try {
      const sessionId = await decrypt(modalData.id);

      const sessionRef = doc(sessionsFirestore, `active_sessions/${sessionId}/accounts/list`);
      const sessionSnap = await getDoc(sessionRef);

      if (sessionSnap.exists()) {
        await updateDoc(sessionRef, {
          data: arrayRemove(modalData.ownerId)
        })

        const userSessionView = doc(usersFirestore, `${user.uid}/data-and-privacy`);
        const userSessionViewSnap = await getDoc(userSessionView);

        if (userSessionViewSnap.exists()) {
          const filteredArray = userSessionViewSnap.data().sessions.filter(obj => {
            return obj.sessionId === modalData.id;
          })

          if (filteredArray) {
            await updateDoc(userSessionView, {
              sessions: arrayRemove(filteredArray[0])
            })
    
            await updateDoc(userSessionView, {
              sessions: arrayUnion({
                expired: true,
                name: modalData.owner,
                ownerId: modalData.ownerId,
                sessionId: modalData.id,
                sessionOwnerId: modalData.sessionOwnerId,
                timestamp: new Date.now()
              })
            })
          }
        }
  
        const userSessionRef = doc(usersFirestore, `${user.uid}/data-and-privacy/sessions/list`);

        await updateDoc(userSessionRef, {
          data: arrayRemove({
            expired: false,
            name: modalData.owner,
            ownerId: modalData.ownerId,
            sessionId: modalData.id,
            sessionOwnerId: modalData.sessionOwnerId,
            timestamp: modalData.timestamp
          })
        })

        await updateDoc(userSessionRef, {
          data: arrayUnion({
            expired: true,
            name: modalData.owner,
            ownerId: modalData.ownerId,
            sessionId: modalData.id,
            sessionOwnerId: modalData.sessionOwnerId,
            timestamp: new Date.now()
          })
        })
      }

      sessionStorage.removeItem('sessions')
      enqueueSnackbar('Se eliminó correctamente.', { variant: 'success', autoHideDuration: 3000, TransitionComponent: SlideTransition })  
      setPrincipalLoading(true);
      handleClose();
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Algo salió mal.', { variant: 'error', autoHideDuration: 3000, TransitionComponent: SlideTransition })  
      setTimeout(() => {
        handleClose();
        setPrincipalLoading(true);
      }, 100)
    }
  }

  const handleDeleteSession = async () => {
    setLoading(true);
    try {
      const sessionId = await decrypt(modalData.id);
      const sessionRef = doc(sessionsFirestore, `active_sessions/${sessionId}/accounts/list`);
      const sessionSnap = await getDoc(sessionRef);

      if (sessionSnap.exists()) {
        const data = sessionSnap.data().data;

        data.forEach(async (id) => {
          try {
            const userDecryptId = await decrypt(id);
            const userRef = doc(usersFirestore, `${userDecryptId}/data-and-privacy`);
            const userSnap = await getDoc(userRef);

            if (userSnap.exists()) {
              const filteredArray = userSnap.data().data.filter(obj => {
                return obj.sessionId === modalData.id;
              })

              if (filteredArray) {
                await updateDoc(userRef, {
                  sessions: arrayRemove(filteredArray[0])
                })
        
                await updateDoc(userRef, {
                  sessions: arrayUnion({
                    expired: true,
                    name: modalData.owner,
                    ownerId: modalData.ownerId,
                    sessionId: modalData.id,
                    sessionOwnerId: modalData.sessionOwnerId,
                    timestamp: new Date.now()
                  })
                })
              }
            }
          } catch (error) {
            console.log(error);
            enqueueSnackbar('Algo salió mal.', { variant: 'error', autoHideDuration: 3000, TransitionComponent: SlideTransition })  
            setTimeout(() => {
              handleClose();
              setPrincipalLoading(true);
            }, 100)
          }
        })

        await deleteSession(sessionId);
        sessionStorage.removeItem('sessions')
        enqueueSnackbar('Se eliminó correctamente.', { variant: 'success', autoHideDuration: 3000, TransitionComponent: SlideTransition })  
        setPrincipalLoading(true);
        handleClose();
      }
      
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Algo salió mal.', { variant: 'error', autoHideDuration: 3000, TransitionComponent: SlideTransition })  
      setTimeout(() => {
        handleClose();
        setPrincipalLoading(true);
      }, 100)
    }
  }

  useEffect(() => {
    async function getSessions() {
      const storage = sessionStorage.getItem('sessions');

      if (storage) {
        setSessions(JSON.parse(storage));
        setLoading(false);
      } else {
        const sessionsRef = doc(usersFirestore, `${auth.currentUser.uid}/data-and-privacy`);
        const sessionsSnap = await getDoc(sessionsRef);

        if (sessionsSnap.exists()) {
            const data = sessionsSnap.data().sessions;

            if (data) {
              const reverseData = data.reverse();
              setSessions(reverseData);
              sessionStorage.setItem('sessions', JSON.stringify(reverseData))
            } else {
              setSessions([])
            }

            setLoading(false)
        } else {
            setSessions([]);
            setLoading(false)
        }
      }
    }
    getSessions();
  }, [])

  return (
    <>
      {loading ?
        <>
          {Array.from({ length: 3}).map((arr) => (
            <div className='btn section session' style={{ marginTop: '0', border: 'none'}} key={arr}>
              <div className={`body expired-false`}>
                  <div className="content">
                      <h3>
                        <Skeleton variant='text' width={'20%'} />
                      </h3>
                      <div><Skeleton variant='test' width={'50%'} /></div>
                  </div>
                  <div className="icon">
                    <Skeleton variant='circular' width={32} height={32} />
                  </div>
              </div>
            </div>
          ))}
        </>
        :
        <>
          {sessions.length > 0 ?
            <>
              {sessions.map((session) => (
                <SectionButton 
                  key={session.sessionId}
                  expired={session.expired}
                  value={`Sesión de ${session.name}`}
                  icon={<Settings style={{ fontSize: '1.35em'}} />}
                  onClick={() => handleOpen(session)}
                />
              ))}
            </>
          :
            <>
            <div
              style={{
                padding: '1rem'
              }}
            >
              No hay sesiones registradas.
            </div>
            </>
          }
        </>
      }
      <Modal
        open={open}
        loading={loading}
        close={handleFullClose}
        title={`Sesión de ${modalData.owner}`}
      >
        {inheritLoading ?
          <Loading />
        :
        <>
          {modalData.expired ?
            <>
            Ya se eliminó esta sesión, estamos preparandonos para que puedas borrar esta sesión de tu historial.
            </>
          :
            <>
              {user.uid === decryptUid ?
                <>
                  <CardButton 
                    title={"Compartir sesión"}
                    icon={<Share />}
                    onClick={handleShare}
                    noSeparator
                  />
                  <CardButton 
                    title={"Eliminar sesión"}
                    variant={"negative"}
                    icon={<Delete />}
                    onClick={handleDeleteSession}
                  />
                </>
                :
                <CardButton 
                  title={"Desconectar sesión"}
                  variant={"negative"}
                  icon={<CloudOff />}
                  onClick={handleDisconnectSession}
                />
              }
            </>
          }
        </>
        }
      </Modal>
      <Modal
        open={shareSync}
        title="Compartir sesión"
        loading={loading}
        close={handleShareSyncClose}
      >
        <div style={{ marginBottom: '1.5rem'}}>
          <TextField 
            className='full-width'
            label="Sesión ID"
            value={modalData.id}
            required
            helperText='Copia este ID para sincronizar esta sesión en otro navegador.'
          />
          <div className='flex flow-column' style={{ marginTop: '1rem', gap: '.25rem'}}>
            <span className='font p-small'></span>
            <span className='font p-small'>Esta información es para uso personal. Ningún trabajador de Yüga te pedira la ID de tu sesión, no la compartas con nadie.</span>
          </div>
        </div>
        <div className="flex flow-row justify-between" style={{ marginLeft: '-1rem'}}>
          <Button 
            type='button' 
            onClick={handleBack} className='btn light'>
            Volver
          </Button>
        </div>
      </Modal>
    </>
  )
}
