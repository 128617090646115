import { createContext, useEffect, useState } from "react";
import { auth } from "../services/firebase/firebase";
import { onAuthStateChanged, signInWithCustomToken } from "firebase/auth";
import Cookies from 'js-cookie';
import axios from "axios";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [ user, setUser ] = useState(undefined);

    const handleLogin = async (uid) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URI}/verifyUid`, {
                uid: uid
            })

            setUser(undefined);

            const customToken = response.data.customToken;

            const user = await signInWithCustomToken(auth, customToken);
            const idToken = await user.user.getIdToken();
            Cookies.set('authToken', idToken, { domain: '.yugacore.com' });

            sessionStorage.removeItem('personal-info');
            sessionStorage.removeItem('sessions');
        } catch (error) {
            console.log(error);
        }
    }

    const applyAuthState = async () => {
        const idToken = Cookies.get('authToken');
        console.log(3);
        console.log(idToken)
        if (idToken) {
            console.log(4);
            try {
                console.log(5);
                setUser(null);
                const response = await axios.post('https://us-central1-yuga-core.cloudfunctions.net/api/verifyToken', { idToken });
                const customToken = response.data.customToken;
                const userCredential = await signInWithCustomToken(auth, customToken);

                localStorage.setItem('authToken', idToken);

                console.log('usuario autenticado', userCredential.user);
                setUser(userCredential.user);
            } catch (error) {
                console.log(6);
                console.log('error al autenticar con el token:', error);
                setUser(auth.currentUser);
            }
        } else {
            console.log(7);
            setUser(null);
        }
    }

    useEffect(() => {
        const idToken = Cookies.get('authToken');
        const localToken = localStorage.getItem('authToken');
        console.log(0);
        
        onAuthStateChanged(auth, (user) => {
            sessionStorage.removeItem('personal-info');
            sessionStorage.removeItem('sessions');
            if (user) {
                if (((!localToken) || (idToken !== localToken)) && idToken) {
                    console.log(1);
                    applyAuthState();
                } else {
                    setUser(user);
                }
            } else {
                setUser(null);
            }
        });
    }, []);

    console.log(user)

    const value = {
        user,
        login: handleLogin
    };
    
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}