import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../context/AuthContext';
import { doc, getDoc } from 'firebase/firestore';
import { usersFirestore } from '../../services/firebase/firebase';
import BasicInfo from './basic-info';
import Section from '../Section';
import ContactInfo from './contact-info';
import YourImage from './your-image';
import Loading from '../../components/Loading';

export default function PersonalInfo() {
    const { user } = useContext(AuthContext);

    const [ loading, setLoading ] = useState(true);
    const [ personalInfo, setPersonalInfo ] = useState([]);

    useEffect(() => {
        async function getPersonalInfo() {
            const storage = sessionStorage.getItem('personal-info');
    
            if (storage) {
                setPersonalInfo(JSON.parse(storage));
                setLoading(false);
            } else {
                const userRef = doc(usersFirestore, `${user.uid}/personal-info`);
                const userSnap = await getDoc(userRef);
        
                if (userSnap.exists()) {
                    const data = userSnap.data().data[0];
                    setPersonalInfo(data);
                    sessionStorage.setItem('personal-info', JSON.stringify(data))
                    setLoading(false);
                } else {
                    console.log('No existe el documento');
                    setLoading(false);
                }
            }
    
        }
        
        if (loading) {
            setTimeout(() => {
                if (user) {
                    getPersonalInfo();
                }
            }, 100)
        }
    }, [user, loading])

    if (loading) return <Loading />

    return (
        <div className='section-container'>
            <Section
                title="Tu imagen"
                desc="Personaliza la forma en que te ven los demás. Los cambios pueden tardar unos minutos en propagarse."
                moreInfo={"#"}
            >
                <YourImage 
                    originalArray={personalInfo}
                    data={personalInfo.basic}
                    setPrincipalLoading={setLoading}
                />
            </Section>
            <Section
                title="Información básica"
                desc="Es posible que cierta información sea visible para otras personas que usan los servicios de Yüga."
                moreInfo="#"
            >
                <BasicInfo 
                    originalArray={personalInfo}
                    data={personalInfo.basic}
                    setPrincipalLoading={setLoading}
                />
            </Section>
            <Section
                title="Información de contacto"
                desc="Tu correo electrónico es público. Estamos trabajando para que puedas vincular mas de una dirección de correo electrónico."
            >
                <ContactInfo 
                    originalArray={personalInfo}
                    data={personalInfo.contact}
                />
            </Section>
            {/* <div>
                <div>
                    <Button className='btn'>
                        <span>Foto de perfil</span>
                        <Avatar 
                            src={personalInfo.basic.photoURL}
                        />
                    </Button>
                    <input 
                        type="text" 
                        placeholder='Cambiar nombre' 
                        value={photoURL}
                        onChange={(e) => setPhotoURL(e.target.value)}
                    />
                    <button onClick={handlePhotoChange}>Cambiar foto</button>
                </div>
                <div>
                    <Button className='btn'>
                        <span>Nombre</span>
                        <span>{personalInfo.basic.name}</span>
                    </Button>
                    <input 
                        type="text" 
                        placeholder='Cambiar nombre' 
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <button onClick={handleNameChange}>Cambiar nombre</button>
                </div>
            </div> */}
        </div>
    )
}
